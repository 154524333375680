<template>
  <section class="cont">
     <!-- 面包屑 -->
     <el-row class="crumbs-box">
        <el-breadcrumb separator-class="el-icon-arrow-right">
           <el-breadcrumb-item>营销云</el-breadcrumb-item>
           <el-breadcrumb-item>分析</el-breadcrumb-item>
           <el-breadcrumb-item>渠道分析</el-breadcrumb-item>
        </el-breadcrumb>
     </el-row>

        <el-row class="reten-full">
           <el-row class="title">不同渠道用户分布</el-row>
           <el-row class="row-data-box">
              <el-col :span="12">过去7日，合计</el-col>
              <el-col :span="12">
                 <date-packer  :datePacker="orderDateVal" @setDatePacker="getOrderDatePacker"></date-packer>
              </el-col>
           </el-row>
           <el-row class="echa-box" id="age-pie"></el-row>
        </el-row>
     <el-row class="secondarymarketing">
        <el-row class="funnel">
           <el-row class="title">不同渠道来源用户人均访问时长</el-row>
           <el-row class="row-data-box">
              <el-col :span="12">过去7日，合计</el-col>
              <el-col :span="12">
                 <date-packer :datePacker="orderDateVals" @setDatePacker="getOrderDatePackers" ></date-packer>
              </el-col>
           </el-row>
           <el-row class="percapitaaccess">
              <el-row class="titles">人均访问时长</el-row>
              <el-row class="schedule" v-for="(item, index) in visit" :key="index" ><span>{{ item.name }}</span>
                 <el-progress  class="yellow" :text-inside="true" :stroke-width="20" :percentage="item.value" ></el-progress>
              </el-row>
           </el-row>
           <el-row class="hourminutesecond">
              <span>0秒</span>
              <span>18分钟40秒</span>
              <span>33分钟20秒</span>
              <span>50分钟</span>
              <span>1小时6分钟40秒</span>
           </el-row>
        </el-row>

        <el-row class="funnel">
           <el-row class="title">不同渠道来源用户次日留存率</el-row>
           <el-row class="row-data-box">
              <el-col :span="12">过去7日，合计</el-col>
              <el-col :span="12">
                 <date-packer :datePacker="orderDateVala" @setDatePacker="getOrderDatePackera"></date-packer>
              </el-col>
           </el-row>
           <el-table :data="remain" style="margin-top: 20px; width: 100%;">
              <el-table-column prop="name" label="下载渠道"></el-table-column>
              <el-table-column prop="value" label="次日留存率"></el-table-column>
           </el-table>
        </el-row>
     </el-row>

  </section>
</template>

<script>
import { urlObj } from '@/api/interface'
import { mapState } from 'vuex'
import {dateFactory} from "@/common/js/common";
export default {
  watch: {
      hotelInfo: {
        handler(newVal, oldVal) {
            if (newVal.id !== oldVal.id  && oldVal) {
              this.hotelId = this.hotelInfo.id
              this.circumference()
              this.timead()
              this.getRetentionRates()
            }
        },
        deep: true
      }
  },
  computed: {
      ...mapState(['hotelInfo'])
  },
  data() {
    return{
      orderDateVal: [],
      orderDateVals: [],
      orderDateVala: [],
      tableData: [],
      remain: [],

      pieOptions: {
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          itemStyle: {normal: {color: '#409efe'}},
          data: [],
          type: 'bar'
        }]
      },
      orderBeginTime: "",
      orderEndTime: "",
      orderBeginTimeds: "",
      orderEndTimeds: "",
      orderBeginTimesc: "",
      orderEndTimesc: "",
      date2: "",
      visit:[],
      hotelId:'',
    }
  },
  mounted() {
    this.hotelId = this.hotelInfo.id
     this.date2 = dateFactory.dateFormat()
     let times = dateFactory.getDistanceAppoint(this.date2, '-', 7, );
     this.orderDateVal = [times,this.date2]
     this.orderDateVala = [times,this.date2]
     this.orderDateVals = [times,this.date2]
     this.orderBeginTime = this.orderBeginTimeds = this.orderBeginTimesc = times
     this.orderEndTime =  this.orderEndTimeds =  this.orderEndTimesc = this.date2
     this.getCombinationPortrait()
     this.accesstime()
    this.getRetentionRates()
     let agepie = this.$echarts.init(document.getElementById("age-pie"))
     window.onresize=function(){
        agepie.resize()
     }

  },
  methods: {
    // 工单统计时间段过滤
    getOrderDatePacker(val) {
      this.orderDateVal = val;
      if (this.orderDateVal) {
        this.orderBeginTime = this.orderDateVal[0];
        this.orderEndTime = this.orderDateVal[1];
      } else {
        this.orderBeginTime = "";
        this.orderEndTime = "";
      }
      this.arrs = [];
      this.getCombinationPortrait();
    },
    getOrderDatePackera(val) {
      this.orderDateVala = val;
      if (this.orderDateVala) {
        this.orderBeginTimesc = this.orderDateVala[0];
        this.orderEndTimesc = this.orderDateVala[1];
      } else {
        this.orderBeginTimesc = "";
        this.orderEndTimesc = "";
      }
      this.remain = [];
    },
    getOrderDatePackers(val) {
      this.orderDateVals = val;
      if (this.orderDateVals) {
        this.orderBeginTimeds = this.orderDateVals[0];
        this.orderEndTimeds = this.orderDateVals[1];
      } else {
        this.orderBeginTimeds = "";
        this.orderEndTimeds = "";
      }
      this.accesstime();
    },
    getRetentionRates(){
      const url = urlObj.getRetentionRate
      const param = {
        hotelId: this.hotelId,
        beginDate: this.orderBeginTime,
        endDate: this.orderEndTime,
      };
      this.$axios.get(url, param).then(res => {
        console.log(res)
        this.remain.push(res.records)
          this.remain.forEach(item=>{
            for (let items in item){
              item.name = items
              item.value = item[items]
            }
          })
      })
    },
    // 不同渠道用户分布
    getCombinationPortrait(){
      this.pieOptions.series[0].data = []
      this.pieOptions.xAxis.data = []
      const url = urlObj.Userdistribution
      const param = {
        hotelId: this.hotelId,
        beginTime: this.orderBeginTime,
        endTime: this.orderEndTime,
      };
      this.$axios.get(url, param).then(res => {
        if (res.success){
          // 年龄分布
          // let ageLevelList = res.records.ageLevelList
          let arr = [];
          arr = res.records;
          let source = "";
          arr.forEach((item) => {
            // console.log(item)
            if (item.source == "APPLETS") {
              item.name = "小程序";
            }
            if (item.source == "APP") {
              item.name = "APP";
            }
            if (item.source == "SSM") {
              item.name = "自助机";
            }
            if (item.source == "WEB") {
              item.name = "PC端";
            }
            if (item.source == "PLATFORM") {
              item.name = "平台";
            }
            if (item.source == "SMS") {
              item.name = "短信";
            }
            if (item.source == "EMAIL") {
              item.name = "邮件";
            }
            if (item.source == "XIAOHONGSHU") {
              item.name = "小红书";
            }
            if (item.source == "WECHAT_OFFICIAL") {
              item.name = "公众号";
            }
            if (item.source == "WECHAT_APPLET") {
              item.name = "微信小程序";
            }
            if (item.source == "DOUYIN_PLATFORM") {
              item.name = "抖音平台";
            }
            if (item.source == "DOUYIN_APPLET") {
              item.name = "抖音小程序";
            }
            if (item.source == "DINGTALK") {
              item.name = "钉钉";
            }
            if (item.source == "DOUYIN") {
              item.name = "抖音";
            }
            if (item.source == "PMS") {
              item.name = "酒店PMS";
            }
          });
          arr.forEach((item) => {
            // console.log(item)
            this.pieOptions.xAxis.data.push(item.name)
            this.pieOptions.series[0].data.push({
              value: item.sourceCount,
            });
          })
        }
              this.$echarts.init(document.getElementById("age-pie")).setOption(this.pieOptions);
      })
    },
    //人均访问时长
    accesstime() {
      this.visit = [];
      const url = urlObj.getAccessTime;
      const param = {
        hotelId: this.hotelId,
        beginLoginTime: this.orderBeginTimeds,
        endLoginTime: this.orderEndTimeds,
      };
      this.$axios.get(url, param).then((res) => {
        // console.log(res);
        let data = res.records;
        // console.log(data);
        if (data === {}) {
          // console.log("=======");
        }
        for (let item in data) {
          // console.log(item, data[item]);
          if (data[item] > 100) {
            data[item] = 100;
          }
          this.visit.push({
            name: item,
            value: data[item],
          });
        }
      });
    },
  }
}
</script>

<style scoped lang="scss">


.secondarymarketing{
   display: flex;
   height: auto;
   margin: 20px;
   margin-bottom: 20px;
   .funnel{
      width: 49%;
      background-color: white;
      box-shadow: 0px 0px 20px #dce7f9;
      height: auto;
      .title{
         height: 50px;
         line-height: 50px;
         background: #f7f7f8;
         padding: 0px 20px;
         i{
            float: right;
            display: inline-block;
            font-size: 20px;
            padding: 1rem;
         }
      }
      .row-data-box{
         height: 50px;
         line-height: 50px;
         padding: 0px 20px;
      }
      .echa-box{
         padding: 0px 20px;
         height: 280px;
      }
      .percapitaaccess{
         .titles{
            margin: 20px 0px;
            color: #529eff;
            text-align: center;
         }
      }
      .hourminutesecond {
         display: flex;
         justify-content: space-around;
         margin-bottom: 20px;
         span {
            display: inline-block;
            font-size: 14px;
            width: 20%;
            text-align: center;
         }
      }
   }
   .funnel:last-child{
      margin-left: 2%;
   }
}

.reten-full{
   margin: 0px 20px;
   background-color: white;
   box-shadow: 0px 0px 20px #dce7f9;
   .title{
      height: 50px;
      line-height: 50px;
      background: #f7f7f8;
      padding: 0px 20px;
      // text-align: center;
      i{
         float: right;
         display: inline-block;
         font-size: 20px;
         padding: 1rem;
      }
   }
   .row-data-box{
      height: 50px;
      line-height: 50px;
      padding: 0px 20px;
   }
   .echa-box{
      padding: 0px 20px;
      height: 280px;
      width: 90%;
   }
}


.top{
  color: #222222; margin-bottom: 20px;
   display: flex;
  .left-box { margin-right: 10px }
  .right-box{ padding-left: 10px }
  .common-box{
     background-color: white;
    .chart-box{
      background: #ffffff; box-shadow: 0 0 8px #DCE7F9;
      .title{
        background: #f7f7f7;
        font-size: 16px;
        padding-left: 30px;
      }
      .chart-cont{
        padding: 20px;
        .chart{ height: 300px;width: 90%;}
      }
    }
  }
}
.userdistribution {
  width: 48%;
  margin-left: 1%;
  float: left;
  box-shadow: 0px 0px 20px #dce7f9;
}
.accesstime {
  width: 100%;
  height: 443px;
  overflow-y: scroll;
  margin-left: 1%;
  float: right;
  box-shadow: 0px 0px 20px #dce7f9;
   background-color: white;
}
//.percapitaaccess .titles {
//  margin: 2rem 0px;
//  color: #529eff;
//  text-align: center;
//}
.schedule span {
  display: inline-block;
  float: left;
  vertical-align: middle;
  margin-top: 15px;
  margin-left: 1rem;
  font-size: 14px;
}
.percapitaaccess .el-progress {
  width: 70%;
  margin: auto;
  margin-top: 1rem;
}

//.title {
//  width: 100%;
//  height: 50px;
//  line-height: 50px;
//  font-size: 16px;
//  padding-left: 1rem;
//  background: #f7f7f8;
//}
.headline .el-row {
  font-size: 14px;
}
.headline .formerly {
  float: left;
  margin-left: 1rem;
}
.headline .tiem {
  float: right;
  margin-right: 1rem;
}
</style>
